.floatingHeader {
	background-color: rgba(var(--bs-primary-rgb), 0.21);
	font-size: 14px;
	position: absolute;
	width: 100%;
	z-index: 100;
	backdrop-filter: blur(10px);
	transform: translateY(-300%);
	transition: transform 0.5s ease;
	&.show {
		position: fixed;
		transform: translateY(0);
		top: 0;
	}
}

.floatingHeaderInner {
	padding: 1.5rem 0;
	display: flex;
	justify-content: space-between;
	align-items: center;
	h1 {
		font-size: 16px;
		font-weight: 700;
	}
	.headerButtons {
		display: flex;
		flex-shrink: 0;
		gap: 1rem;
		align-items: center;
	}
}

.floatingPriceSection {
	position: absolute;
	right: 0;
	top: 2rem;
	z-index: 1;
	.priceSectionInner {
		background: #fff;
		width: 30vw;
		margin-left: auto;
	}
	transition: all 0.5s ease;
	&.stickFloating {
		position: fixed;
		top: 9rem;
		width: 100%;
	}
}

.titleSection {
	background-color: rgba(var(--bs-primary-rgb), 0.21);
	padding: 1.5rem 0;
}

.acrediationBadge {
	font-weight: bold;
	margin-right: 2px;
	color: #fff;
	background-color: var(--bs-primary);
	padding: 2px 6px;
	text-transform: uppercase;
	font-size: 10px;
	border-radius: 2px;
}

.breadcrumbTree {
	display: inline-block;
	padding: 0;
	margin-bottom: 0;
	li {
		display: inline-block;
		font-weight: 500;
		text-transform: uppercase;
		a {
			color: var(--bs-primary);
			&:hover {
				color: var(--bs-primary);
			}
		}
	}

	li + li {
		margin-left: 10px;
	}

	li + li:before {
		content: '/';
		display: inline-block;
		color: var(--bs-primary);
		margin-right: 10px;
	}
}

.contentSummary {
	margin-bottom: 0;
	display: flex;
	gap: 1.5rem;
	span {
		position: relative;
	}
	span:not(:last-child):after {
		content: ' ';
		position: absolute;
		width: 5px;
		height: 5px;
		background: #000;
		border-radius: 100%;
		top: 35%;
		right: -20%;
	}
}

.courseContentAccordian {
	font-size: 13px;
	:global(.accordion-button)::after {
		position: absolute;
		margin-left: unset;
	}
	:global(.accordion-button) {
		background-color: var(--bs-light) !important;
	}
}

.previewCover {
	position: relative;
	text-align: center;
	min-height: 200px;
	img {
		width: 100%;
		height: auto;
	}
	.previewOverlay {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		opacity: 0.8;
		background-color: #000;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		cursor: pointer;
	}
	.previewPlayBtn {
		width: 0;
		height: 0;
		border-top: 25px solid transparent;
		border-bottom: 25px solid transparent;
		border-left: 40px solid #fff;
		border-right: 0;
		cursor: pointer;
		margin: 0 auto;
		background-color: transparent;
	}
	.PreviewText {
		position: absolute;
		bottom: 0;
		font-weight: bold;
		font-size: 14px;
		color: #fff;
	}
}

.videoPreviewModalContainer {
	position: fixed;
	top: 0;
	left: 0;
	width: 0;
	height: 0;
	z-index: 999;
	opacity: 0;
	transition: opacity 0.3s ease-in-out;
	&.open {
		width: 100%;
		height: 100%;
		opacity: 1;
		.videoPreviewBackdrop {
			opacity: 1;
		}
		.previewModalInner {
			transform: translateY(0);
			opacity: 1;
		}
	}
	.videoPreviewBackdrop {
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.7);
		z-index: 998;
		opacity: 0;
		transition: opacity 0.3s ease-in-out;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.previewModalInner {
		position: relative;
		z-index: 999;
		width: 70%;
		transform: translateY(-50px);
		opacity: 0;
		transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
		.crossBtn {
			background-color: #000;
			padding: 0;
			width: 30px;
			height: 30px;
			color: #fff;
			border-radius: 100%;
			position: absolute;
			top: 8px;
			right: 8px;
		}
	}
}

@media only screen and (max-width: 767px) {
	.floatingHeaderInner {
		flex-direction: column;
		.headerButtons {
			margin-right: auto;
			margin-top: 1.5rem;
		}
	}
	.floatingPriceSection {
		display: none;
	}
	.videoPreviewModalContainer {
		.previewModalInner {
			width: 90%;
		}
	}
}

@media only screen and (min-width: 768px) {
	.leftContentSection {
		width: calc(100% - 30vw - 8px);
		z-index: 3;
		position: relative;
	}
}
