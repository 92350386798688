.container {
	position: relative;
	min-height: 100vh;
	height: 100%;
}
.errorWrapper {
	display: flex;
	position: absolute;
	top: 0;
	left: 0;
	background-color: rgba(0, 0, 0, 0.7);
	height: 100vh;
	width: 100vw;
	align-items: center;
	justify-content: center;
	flex-direction: column;
}
.errorInner {
	text-align: center;
	max-width: 450px;
	padding: 1rem;
	margin: 1rem;
}
.errorIcon {
	width: 4rem;
	height: auto;
	margin: 1rem auto;
}
.refreshBtn {
	background-color: transparent;
	border: 1px solid var(--bs-primary);
	color: var(--bs-primary);
	padding: 0.8rem 4rem;
	margin: 1rem 0;
	text-transform: uppercase;
	font-size: 1.1rem;
	border-radius: 0;
	&:hover {
		color: #fff;
		background-color: var(--bs-primary);
		transition: all 0.5s ease-in-out;
	}
}
