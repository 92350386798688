.img {
	width: 100px;
	height: 100px;
	border-radius: 5px;
}

.avatar {
	width: 100px;
	height: 100px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 40px;
	font-weight: 700;
	border-radius: 5px;
	color: #fff;
}
