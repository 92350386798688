.page-container {
	display: flex;
	margin-left: auto;
	margin-right: auto;
	padding: 10px;
	margin-top: 20px;
	margin-bottom: 20px;
	box-sizing: border-box;
	background-color: #ffffff;
	border-radius: 5px;
	border: 1px solid transparent;
	box-shadow: 0 3px 9px rgba(0, 0, 0, 0.1);
}

.products-amount {
	padding-left: 10px;
	margin: 0px;
}
