.passwordInput {
	z-index: 1 !important;
	padding-right: 28px;
}

.loadingOverlay {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	background-color: #151515b5;
	z-index: 10;
	left: 0;
	display: flex;
	flex-direction: column;
	color: #fff;
	gap: 1rem;
	font-size: 1.2rem;
	align-items: center;
	justify-content: center;
}

.successOverlay {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	background-color: #fff;
	z-index: 10;
	display: flex;
	flex-direction: column;
	gap: 1rem;
	text-align: center;
	padding: 3rem;
	font-size: 1.2rem;
	align-items: center;
	justify-content: space-evenly;
	border-radius: var(--bs-border-radius);
}

.successContainer {
	overflow: hidden;
	height: 10rem;
}
