.pageContainer {
	max-width: 100% !important;
	padding: 0;
}

.breadcrumb {
	padding: 30px 0px;
	border-bottom: 1px solid var(--bs-border-color);
	margin-bottom: 30px;

	.breadcrumbTree {
		display: inline-block;

		li {
			display: inline-block;
			font-size: 12px;
			font-weight: 500;
			text-transform: uppercase;

			a {
				color: #8d99ae;

				&:hover {
					color: var(--bs-primary);
				}
			}
		}

		li + li {
			margin-left: 10px;
		}

		li + li:before {
			content: '/';
			display: inline-block;
			color: #8d99ae;
			margin-right: 10px;
		}
	}
}

.productTab {
	margin-top: 3rem;
	margin-bottom: 3rem;
}

.tabNav {
	position: relative;
	text-align: center;
	padding: 15px 0px;
	border: none !important;
	display: flex;
	justify-content: center;

	&:after {
		content: '';
		position: absolute;
		left: 0;
		right: 0;
		top: 50%;
		height: 1px;
		background-color: #e4e7ed;
		z-index: -1;
	}

	li {
		display: inline-block;
		background: #f2f2f7;
		padding: 0px 10px;

		.tabButton {
			display: block;
			font-weight: 700;
			color: #8d99ae;
			font-size: 14px;
			border: none !important;

			&:after {
				content: '';
				display: block;
				width: 0%;
				height: 2px;
				background-color: var(--bs-primary);
				-webkit-transition: 0.2s all;
				transition: 0.2s all;
			}

			&:global(.active) {
				color: var(--bs-primary);
				background-color: transparent;
				border: none;
			}

			&:hover:after,
			&:focus:after,
			&:global(.active):after {
				width: 100%;
			}
		}
	}

	li + li {
		margin-left: 10px;
	}
}

.typography {
	font-size: 14px;
	font-weight: 400;
	line-height: 1.4;
	color: #555555;
}

.productDetails {
	.product-name {
		text-transform: uppercase;
		font-size: 18px;
		font-weight: 700;
	}

	.productReviewContainer {
		display: flex;
		align-items: center;
		gap: 0.5rem;

		.review-link {
			font-size: 12px;
			color: inherit;
		}
	}

	.product-price {
		display: inline-block;
		font-size: 24px;
		margin-top: 10px;
		margin-bottom: 5px;
		color: var(--bs-primary);

		.product-old-price {
			font-size: 70%;
			font-weight: 400;
			color: #8d99ae;
		}
	}

	.product-unavailable,
	.product-available {
		font-size: 12px;
		text-transform: uppercase;
		font-weight: 700;
		margin-left: 30px;
	}

	.product-available {
		color: var(--bs-primary);
	}

	.product-unavailable {
		color: #bc240c;
	}

	.add-to-cart {
		margin-bottom: 30px;
		display: flex;

		.qty-label {
			font-weight: 500;
			font-size: 12px;
			text-transform: uppercase;
			margin-right: 15px;
			margin-bottom: 1rem;
			display: flex;
			align-items: center;

			.input-number {
				width: 90px;
				display: flex;
				align-items: center;

				input {
					padding: 0;
					margin: 0;
					border-left: none;
					border-right: none;
					font-size: 14px;
					text-align: center;
				}

				.qty-up,
				.qty-down {
					border: 1px solid var(--bs-border-color);
					background-color: #fff;
					cursor: pointer;
					user-select: none;
					display: flex;
					align-items: center;
					justify-content: center;
					font-size: 16px;
					height: 40px;
					width: 40px;
					user-select: none;

					&:hover {
						background-color: #e4e7ed;
						color: var(--bs-primary);
					}
				}

				.qty-up {
					border-left: none;
				}

				.qty-down {
					border-right: none;
				}
			}
		}
	}
}

.add-to-cart-btn {
	position: relative;
	border: 2px solid transparent;
	height: 40px;
	padding: 0 30px;
	background-color: var(--bs-primary);
	color: #fff;
	text-transform: uppercase;
	font-weight: 700;
	border-radius: 40px;
	-webkit-transition: 0.2s all;
	transition: 0.2s all;

	&:hover:not(:disabled) {
		background-color: #fff;
		color: var(--bs-primary);
		border-color: var(--bs-primary);
		padding: 0px 30px 0px 50px;

		.cartIcon {
			opacity: 1;
			visibility: visible;
		}
	}

	.cartIcon {
		position: absolute;
		left: 1.5rem;
		bottom: 9px;
		color: var(--bs-primary);
		opacity: 0;
		visibility: hidden;
	}

	&:disabled {
		opacity: 0.6;
	}
}

@media only screen and (max-width: 400px) {
	.add-to-cart {
		flex-direction: column;

		.add-to-cart-btn {
			margin-top: 0.5rem;
		}
	}
}
