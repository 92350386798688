.card {
	height: 100%;
	font-family: 'Open Sans', Helvetica, Arial, sans-serif;
	font-size: 12px;
	flex: 0 0 auto;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	position: relative;
	padding: 8px;
	cursor: pointer;
	border-radius: 5px;
	border: 1px solid transparent;
	box-shadow: 0 3px 9px rgba(0, 0, 0, 0.1);
	overflow: hidden;
	background-color: #fff;
}

.img-wrapper {
	display: flex;
	position: relative;
}

.card-image {
	height: 150px;
	margin: auto;
	width: 100%;
}

.courseTypeBadge {
	padding: 2px 4px;
	border: 1px solid var(--bs-primary);
	color: var(--bs-primary);
	display: inline-block;
	border-radius: 4px;
	font-weight: bold;
	font-size: 9px;
	margin-bottom: 5px;
}

.card-title {
	text-overflow: ellipsis;
	font-weight: 400;
	font-size: 16px;
	line-height: 1.3em;
	color: #212529;
}

.card-stats {
	display: flex;
	align-items: center;
	margin-bottom: 5px;
}

.card-stats > span {
	font-weight: 400;
}

/* ADD */
.price-div {
	height: 45px;
	position: relative;
}

.card-price {
	margin-top: auto;
	margin-bottom: 10px !important;
	font-weight: 700;
	color: #ef2809;
	padding-left: 10px;
	position: absolute;
	bottom: 0;
}

.acreditat {
	position: absolute;
	top: 0;
	left: 0;
	background-color: #fff;
	padding: 5px 7px;
	border-radius: 5px;
	font-weight: bold;
	text-transform: uppercase;
}

.product-old-price {
	font-size: 95%;
	font-weight: 400;
	padding-left: 10px;
	color: #8d99ae;
}

.card-badge-price {
	position: absolute;
	top: 0;
	right: 0;
	background-color: #ef2809;
	padding: 5px 7px;
	border-radius: 5px;
	font-weight: bold;
	color: #fff;
}
.seatAvailablity {
	position: absolute;
	bottom: 0;
	right: 0;
	background-color: var(--bs-danger);
	z-index: 10;
	color: #fff;
	font-weight: bold;
	padding: 0 4px;
}
.card-details {
	padding: 10px 0;
	min-height: 80px;
}

.buttons {
	align-items: center;
	margin: 0px;
	padding: 5px 0;
	display: flex;
	justify-content: space-between;
}

.heart {
	color: #fafbfd;
	text-transform: uppercase;
	cursor: pointer;
	&:hover {
		color: #ef2809;
	}
	&.active {
		color: #ef2809;
	}
}
