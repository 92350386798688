.courseTypeBadge {
	padding: 2px 4px;
	border: 1px solid var(--bs-primary);
	color: var(--bs-primary);
	display: inline-block;
	border-radius: 4px;
	font-weight: bold;
	font-size: 9px;
	margin-bottom: 5px;
}

.acrediationBadge {
	font-weight: bold;
	margin-right: 5px;
	color: #fff;
	background-color: var(--bs-primary);
	padding: 2px 6px;
	text-transform: uppercase;
	font-size: 10px;
	border-radius: 2px;
}
.actionButton {
	border-radius: 0;
	text-transform: uppercase;
	font-weight: bold;
	&:hover {
		color: #fff;
	}
}

.tabNav:global(.nav) {
	position: relative;
	text-align: center;
	border: none;
	margin-bottom: 1rem;
	border-bottom: 1px solid var(--bs-border-color);

	&:after {
		content: '';
		position: absolute;
		left: 0;
		right: 0;
		top: 50%;
		height: 1px;
		background-color: #e4e7ed;
		z-index: -1;
	}

	li {
		display: inline-block;
		background: #f2f2f7;

		.tabButton {
			display: block;
			font-weight: 700;
			color: #8d99ae;
			border: none !important;
			padding-bottom: 0;
			line-height: 2;

			&:after {
				content: '';
				display: block;
				width: 0%;
				height: 2px;
				background-color: var(--bs-primary);
				-webkit-transition: 0.2s all;
				transition: 0.2s all;
			}

			&:global(.active) {
				color: var(--bs-primary);
				background-color: transparent;
				border: none;
			}

			&:hover:after,
			&:focus:after,
			&:global(.active):after {
				width: 100%;
			}
		}
	}
}
