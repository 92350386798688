.favorite-item {
	padding: 0;
	height: 180px;
}

.favorite-item-title {
	text-overflow: wrap;
	line-height: 1.5em;
	padding: 0 15px;
	cursor: pointer;
}

.title-text {
	color: #212529 !important;
	font-size: 14px;
}

.fav-img {
	cursor: pointer;
}

.right-col {
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	min-width: 135px;
	margin-left: auto;
}

.stock {
	font-size: 14px;
	font-weight: 500;
	color: var(--bs-primary);

	&.warning {
		color: orange;
	}

	&.active {
		color: #ef2809;
	}
}

.fav-item-price {
	color: #ef2809;
	font-weight: 600;
}

.add-btn {
	margin-top: auto;
}

.fav-remove-item {
	font-weight: 400;
	font-size: 12px;
	margin-top: 15px;
	color: #0d6efd;
	cursor: pointer;
}

.horizontal-line {
	display: block;
	margin: 1rem 0;
	color: inherit;
	border: 0;
	border-top: 1px solid;
	opacity: 0.25;
	color: grey;
}

@media only screen and (max-width: 767.9px) {
	.fav-img {
		height: 120px;
	}

	.add-btn {
		font-size: 14px;
		margin-top: 10px;
	}

	.title-text {
		font-size: 12px !important;
	}

	.favorite-item {
		height: 140px;
	}
}

@media only screen and (max-width: 575px) {
	.fav-img {
		display: none;
	}
}
