.heroSection {
	background-color: #14a79a36;
}
.heroImage {
	box-shadow: 0 20px 40px rgba(24, 44, 74, 0.05);
}
.badge {
	font-size: 10px;
	background-color: #fff;
	padding: 3px 6px;
	border-radius: 5px;
	display: inline-block;
	color: var(--bs-primary);
	text-transform: uppercase;
	font-weight: 700;
	border: 1px solid;
}

.discountBadge {
	margin: 0;
	position: absolute;
	background-color: var(--bs-danger);
	color: #fff;
	padding: 3px 6px;
	border-radius: 5px;
	top: 0;
	right: 0;
	font-weight: 700;
}
