.leftSidebar {
	width: 100%;
	padding: 0;
	border: none !important;
}

.collapsableSidebar {
	max-height: 50rem;
	transition: all 700ms ease-in-out;
	&.sectionCollapsed {
		overflow: hidden;
		max-height: 0;
		transition: all 0.5s cubic-bezier(0, 1, 0, 1);
	}
}

.panelHeader {
	background-color: var(--bs-primary);
	color: #fff;
	padding: 0.75rem 0.5rem;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	h2 {
		font-size: 16px;
		font-weight: 700;
		text-align: center;
		text-transform: uppercase;
		margin: 0;
		flex: 1;
	}
	.collapseIcon {
		flex: 0 0 2rem;
		height: auto;
	}
}

.panel {
	border: 0px;
	border-radius: 0px;
	box-shadow: none;
	margin-bottom: 0px;
}

.panelHeading {
	border: 0 none;
	color: #ffffff;
	padding: 5px;
	margin: 0.25rem 0;
	h4 {
		margin-bottom: 0;
	}
}
.categoryItemTitle {
	color: #303030;
	font-size: 14px;
	text-transform: uppercase;
	font-weight: 500;
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 0;
	cursor: pointer;
	&.active,
	&:hover {
		color: var(--bs-primary);
		.categoryBadge {
			fill: var(--bs-primary);
		}
	}
	.categoryBadge {
		transition: all 500ms ease-in-out;
	}
	.upsideArrow {
		transform: rotate(180deg);
	}
}

.subList {
	list-style-type: none;
	padding-left: 1.5rem;
	li {
		margin: 0.5rem 0;
	}
}
.categorySubItemTitle {
	font-size: 12px;
	padding: 0.5rem;
	display: inline-block;
}

.collapsable {
	max-height: 0;
	height: auto;
	overflow: hidden;
	transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);
	&.show {
		transition: all 700ms ease-in-out;
		max-height: 50rem;
	}
}

@media (min-width: 768px) {
	.collapsableSidebar.sectionCollapsed {
		max-height: 50rem;
		transition: all 700ms ease-in-out;
	}
}
