.breadcrumb {
	padding: 2rem 0px 1rem;
	border-bottom: 1px solid var(--bs-border-color);
	margin-bottom: 2rem;
	.breadcrumb-header {
		display: inline-block;
		margin-top: 0px;
		margin-bottom: 0px;
		margin-right: 15px;
		text-transform: uppercase;
	}
	.breadcrumbTree {
		display: inline-block;
		li {
			display: inline-block;
			font-size: 12px;
			font-weight: 500;
			text-transform: uppercase;
			a {
				color: #8d99ae;
				&:hover {
					color: var(--bs-primary);
				}
			}
		}
		li + li {
			margin-left: 10px;
		}
		li + li:before {
			content: '/';
			display: inline-block;
			color: #8d99ae;
			margin-right: 10px;
		}
	}
}
