@media only screen and (max-width: 767.9px) {

    .cart-total>h3,
    .total-price-col>h3 {
        font-size: 14px;
    }

    .text-price>h4 {
        font-size: 12px;
    }
}

@media only screen and (max-width: 581px) {

    .cart-item>img {
        display: none;
    }

    .total-price-col>Button {
        font-size: 12px;
    }

    .cart-total,
    .total-price-col {
        padding: 0 5px !important;
        min-width: 65px !important;
    }
}