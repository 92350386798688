.card {
	// width: 250px;
	height: 320px;
	font-family: 'Open Sans', Helvetica, Arial, sans-serif;
	font-size: 12px;
	flex: 0 0 auto;
	display: flex;
	flex-direction: column;
	position: relative;
	padding: 5px;
	cursor: pointer;
	border-radius: 5px;
	border: 1px solid transparent;
	box-shadow: 0 3px 9px rgba(0, 0, 0, 0.1);
	overflow: hidden;
}

.card:not(.non-hoverable):hover {
	box-shadow: 2px 2px 5px 5px rgba(0, 0, 0, 0.2);
}

.card {
	&.non-hoverable {
		cursor: default;
	}
}

.img-wrapper {
	display: flex;
}

.card-image {
	height: 150px;
	margin: auto;
}

/* ADD */
.card-title {
	text-overflow: ellipsis;
	font-weight: 400;
	font-size: 16px;
	line-height: 1.3em;
	color: #212529;
}

.card-stats {
	display: flex;
	align-items: center;
	margin-bottom: 5px;
}

.card-stats>span {
	font-weight: 400;
}

.card-star {
	height: 14px;
	margin-right: 3px;
}

/* ADD */
.price-div {
	height: 45px;
	position: relative;
}

.card-price {
	margin-top: auto;
	margin-bottom: 10px !important;
	font-weight: 700;
	color: #ef2809;
	padding-left: 10px;
	position: absolute;
	bottom: 0;
}

.card-badge {
	position: absolute;
	top: 6px;
	left: 6px;
	background-color: #fff;
	padding: 5px 7px;
	border-radius: 5px;
	font-weight: bold;
	color: orange;

	&.active {
		color: #ef2809;
	}
}

.product-old-price {
	font-size: 95%;
	font-weight: 400;
	padding-left: 10px;
	color: #8d99ae;
}

.card-badge-price {
	position: absolute;
	top: 6px;
	right: 6px;
	background-color: #ef2809;
	padding: 5px 7px;
	border-radius: 5px;
	font-weight: bold;
	color: #fff;
}

.card-details {
	padding: 10px;
	min-height: 80px;
}

.gray {
	margin-left: 3px;
}

.buttons {
	align-items: center;
	margin: 0px;
	padding: 5px 15px;
}

.svg:not(.default) {
	color: #ef2809;

	&:hover {
		color: #ef2809;
	}

	&.active {
		color: #ef2809;
	}
}

.heart {
	color: #fafbfd;
	text-transform: uppercase;
	cursor: pointer;

	&:hover {
		color: #ef2809;
	}

	&.active {
		color: #ef2809;
	}
}