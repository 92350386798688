.form-group {
	font-weight: 400 !important;
	font-size: 18px !important;
}

.form-control {
	line-height: 22px;
	font-size: 16px !important;
}

.style-text {
	font-weight: 400 !important;
	font-size: 16px;
}

.submit {
	font-size: 16px !important;
	font-weight: 600 !important;
	width: 100%;
}

label {
	font-size: 14px;
	font-weight: 600;
	color: rgb(34, 34, 34);
}

.link-primary {
	cursor: pointer;
}
