.titleSection {
	background-color: rgba(var(--bs-primary-rgb), 0.21);
	padding: 1.5rem 0;
}
.breadcrumbTree {
	display: inline-block;
	padding: 0;
	margin-bottom: 0;
	li {
		display: inline-block;
		font-weight: 500;
		text-transform: uppercase;
		a {
			color: var(--bs-primary);
			&:hover {
				color: var(--bs-primary);
			}
		}
	}

	li + li {
		margin-left: 10px;
	}

	li + li:before {
		content: '/';
		display: inline-block;
		color: var(--bs-primary);
		margin-right: 10px;
	}
}
.textLessonContainer {
	font-size: 14px;
	margin: 2rem 0;
	border: 1px solid #ccc;
	padding: 1rem;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	aspect-ratio: 16/9;
	overflow: scroll;
	.textRTE {
		:global(.ql-container) {
			border: none;
		}
		:global(.ql-editor) {
			padding: 0;
		}
	}
}

.contentSummary {
	margin-bottom: 0;
	display: flex;
	gap: 1.5rem;
	span {
		position: relative;
	}
	span:not(:last-child):after {
		content: ' ';
		position: absolute;
		width: 5px;
		height: 5px;
		background: #000;
		border-radius: 100%;
		top: 35%;
		right: -20%;
	}
}

.courseContentAccordian {
	font-size: 13px;
	:global(.accordion-button)::after {
		position: absolute;
		margin-left: unset;
	}
	:global(.accordion-button) {
		background-color: var(--bs-light) !important;
	}
}

.lessonTitle {
	margin: 0 0.5rem;
	cursor: pointer;
	&.active,
	&:hover {
		color: var(--bs-primary);
	}
}

.sliderButton {
	position: absolute;
	z-index: 10;
	height: 100%;
	top: 0;
	background-color: rgba(0, 0, 0, 0.5);
	width: 10%;
	max-width: 4rem;
	color: #fff;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	&.nextButton {
		right: 0;
		transform: rotate(180deg);
	}
}

.slideImage {
	width: 100%;
	aspect-ratio: 16/9;
}

.playerContainer {
	background-color: #000;
	display: flex;
	justify-content: center;
	position: relative;
	padding-top: 56.25%;
	.player {
		position: absolute;
		top: 0;
		left: 0;
	}
}
