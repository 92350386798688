.navbar {
	display: flex;
	flex-direction: row;
	font-family: 'Open Sans', sans-serif;
	font-weight: 400;
	font-size: 16px;
	background-color: #fafbfd;
}

.dropdown-item {
	font-size: 14px;
	line-height: 26px;
}

.align-center {
	display: flex;
	margin-left: 20px;
	align-items: center;
}

.no-click {
	pointer-events: none;
}

.cart-icon,
.user-icon-connect,
.favorite-icon {
	margin-right: 5px;
	height: 20px !important;
	width: 20px !important;
}

.user-icon {
	height: 20px !important;
	width: 20px !important;
}

.item {
	margin-left: 20px;
}

.cart-items {
	width: 15px;
	height: 15px;
	background-color: red;
	color: white;
	font-size: 12px;
	border-radius: 50%;
	text-align: center;
}

.logo:hover {
	cursor: pointer;
}

.item:hover,
.item-right:hover {
	color: var(--bs-primary);
	cursor: pointer;
}

.nav1 > .item {
	border-bottom: 2px solid white;
}

.nav1 > .active {
	border-bottom: 2px solid var(--bs-primary);
}

.favorite-badge {
	position: fixed;
	width: 10px;
	height: 10px;
	border-radius: 50%;
}

#lblCartCount,
#lblFavoriteCount {
	font-size: 12px;
	background: #ff0000;
	color: #fff;
	padding: 1px 3px;
	margin-top: -10px;
	margin-left: -12px;
	border-radius: 15px;
}

.badge {
	padding: 2px 4px !important;
	-webkit-border-radius: 15px;
	-moz-border-radius: 15px;
	border-radius: 15px;
	font-size: 14px !important;
}

@media only screen and (max-width: 992px) {
	.register,
	.cart,
	.favorite {
		display: none;
	}
}

@media only screen and (max-width: 767.9px) {
	.item,
	.margine-left,
	.align-center {
		margin-left: 10px !important;
	}

	.register,
	.cart,
	.favorite {
		display: block;
		margin-left: 0px !important;
	}

	.user-icon,
	.cart-icon,
	.user-icon-connect,
	.favorite-icon,
	#lblCartCount,
	#lblFavoriteCount {
		display: none;
	}

	.nav1 > .active {
		border-bottom: 0px;
	}
}
