.orderListRow {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 1rem;
	font-size: 14px;
	p {
		margin-bottom: 0;
	}
}

.order-details {
	padding: 0px 2rem 2rem;
	.submitButton {
		border-radius: 22px;
		color: #fff !important;
		font-size: 14px;
		text-transform: uppercase;
		font-weight: 700;
		margin-top: 1rem;
		width: 100%;
		padding: 0.8rem 0;
	}
}
