.fit>h6,
.fit>p {
    font-size: small;
}

.fit {
    min-width: 100px;
}

@media only screen and (max-width: 767.9px) {

    .fit>h6,
    .fit>p {
        font-size: 12px;
        padding: 0 15px;
    }
}

@media only screen and (max-width: 581px) {

    .fit>h6,
    .fit>p {
        font-size: 10px;
        padding: 0 5px;
    }
}