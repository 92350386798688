.footerContainer {
	background-color: #dbeaee;
	color: #303030;
}

.section {
	padding-top: 30px;
	padding-bottom: 30px;
}

.bottomFooter {
	background-color: rgba(var(--bs-primary-rgb), 0.21);
}

.footerColumn {
	margin: 30px 0px;
	font-size: 14px;
}

.footerColumnTitle {
	color: #303030;
	text-transform: uppercase;
	font-size: 18px;
	margin: 0px 0px 30px;
}

.footerLinks {
	list-style-type: none;
	margin: 0;
	padding: 0;
	font-weight: 500;

	li + li {
		margin-top: 15px;
	}

	li {
		a {
			color: #303030;
		}
	}
}

.footerIcon {
	margin-right: 8px;
	color: #303030;
	width: 14px;
	text-align: center;
}

.socalIcon {
	width: 20px;
	color: #303030;
}

.copyright {
	font-size: 14px;
	margin: 0;
}
