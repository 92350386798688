.ratingAvg {
	font-size: 24px;
	font-weight: 700;
	margin-bottom: 15px;
	display: flex;
	align-items: center;
	gap: 0.5rem;
}

.rating {
	padding-left: 0;
	li {
		display: flex;
		align-items: center;
		margin: 5px 0px;
		.ratingProgress {
			position: relative;
			display: inline-block;
			height: 9px;
			background-color: #e4e7ed;
			width: 120px;
			margin: 0px 10px;
			border-radius: 5px;
			> div {
				background-color: var(--bs-primary);
				position: absolute;
				left: 0;
				top: 0;
				bottom: 0;
				border-radius: 5px;
			}
		}
		.sum {
			display: inline-block;
			font-size: 12px;
			color: #8d99ae;
		}
	}
}

.reviews {
	list-style-type: none;
	font-size: 14px;
	padding-left: 0;
	li {
		display: flex;
		gap: 2rem;
		margin-bottom: 1rem;
	}
	.reviewHeading {
		height: 70px;
		.name {
			margin-bottom: 5px;
			margin-top: 0px;
		}
		.date {
			color: #8d99ae;
			font-size: 10px;
			margin: 0;
		}
	}
	.reviewBody {
		min-height: 70px;
	}
}
