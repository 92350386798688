.cart-item {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	align-items: center;
	margin-top: 10px;
	border-radius: 5px;
	padding: 5px;
	width: 100%;
}

.cart-item-title {
	text-overflow: wrap;
	line-height: 1.5em;
	height: 4.5em;
	margin-left: 10px;
	width: 100%;
	padding-right: 15px;
}

.amount-div {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	min-width: 7rem;
}

.toggle {
	padding: 5px;
}

.amount {
	font-weight: 400;
	margin-bottom: 0px;
	font-size: 14px;
}

.price-col {
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	min-width: 11rem;
	padding-right: 10px;
}

.item-price {
	font-weight: 700;
}

.remove-item {
	font-weight: 400;
	font-size: 12px;
	margin-top: 10px;
	color: #0d6efd;
	cursor: pointer;
}

svg:not(.default) {
	cursor: pointer;
	height: 20px;
	width: 20px;
}

.badge-container {
	display: flex;
	flex-direction: row;
}

.item-badge {
	// padding: 5px 7px;
	// display: flex;
	// align-self: flex-end;
	border-radius: 2px;
	font-weight: bold;
	font-size: 12px;
	color: orange;

	&.active {
		color: #ef2809;
	}
}

@media only screen and (max-width: 767.9px) {
	.cart-item-title > h4 {
		font-size: 12px;
	}

	.cart-item-title {
		margin: 0px;
		padding: 5px;
		height: 100%;
	}
}

@media only screen and (max-width: 581px) {
	.cart-item > img {
		display: none;
	}

	.item-price {
		font-size: 14px;
	}

	.price-col {
		min-width: 100px !important;
	}
}
