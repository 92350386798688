.modalContainer {
	position: absolute;
	background-color: rgba(0, 0, 0, 0.4);
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100vw;
	height: calc(100% + 1.5rem);
	top: 0;
	margin-left: 50%;
	transform: translateX(-50%);
}

.modalCard {
	background-color: #fff;
	width: 300px;
	height: 300px;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 1rem;
	margin: auto;
	margin-top: calc(50vh - 150px);
	text-align: center;
	font-size: 1rem;
	box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}
