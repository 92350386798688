.fav-img {
	cursor: pointer;
}
.acreditat {
	position: absolute;
	top: 3px;
	left: 3px;
	background-color: #fff;
	padding: 2px 5px;
	border-radius: 4px;
	font-weight: bold;
	text-transform: uppercase;
	font-size: 10px;
}

.courseTypeBadge {
	padding: 2px 4px;
	border: 1px solid var(--bs-primary);
	color: var(--bs-primary);
	display: inline-block;
	border-radius: 4px;
	font-weight: bold;
	font-size: 9px;
	margin-bottom: 5px;
}

.right-col {
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	min-width: 135px;
	margin-left: auto;
}

.fav-remove-item {
	font-weight: 400;
	font-size: 12px;
	margin-top: 15px;
	color: #0d6efd;
	cursor: pointer;
}

@media only screen and (max-width: 767.9px) {
	.fav-img {
		height: 120px;
	}
}

@media only screen and (max-width: 575px) {
	.left-col {
		display: none;
	}
}
