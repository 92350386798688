.card {
	height: 100%;
	font-family: 'Open Sans', Helvetica, Arial, sans-serif;
	font-size: 12px;
	flex: 0 0 auto;
	display: flex;
	justify-content: flex-start;
	gap: 1rem;
	padding: 8px;
	border-radius: 5px;
	border: 1px solid transparent;
	box-shadow: 0 3px 9px rgba(0, 0, 0, 0.1);
	overflow: hidden;
	background-color: #fff;
}

.cover-wrapper {
	position: relative;
}
.card-image {
	height: 150px;
	width: 100%;
}

.courseTypeBadge {
	padding: 2px 4px;
	border: 1px solid var(--bs-primary);
	color: var(--bs-primary);
	display: inline-block;
	border-radius: 4px;
	font-weight: bold;
	font-size: 9px;
	margin-bottom: 5px;
}

.content-main {
	display: flex;
	justify-content: space-between;
	flex-direction: column;
	width: calc(100% - 225px - 1rem);
}

.card-title {
	text-overflow: ellipsis;
	font-weight: 400;
	font-size: 16px;
	line-height: 1.3em;
	color: #212529;
}

.card-stats {
	display: flex;
	align-items: center;
	margin-bottom: 5px;
}

.card-stats > span {
	font-weight: 400;
}
.card-price {
	font-weight: 700;
	color: #ef2809;
	padding-left: 10px;
}

.acreditat {
	position: absolute;
	top: 6px;
	left: 6px;
	background-color: #fff;
	padding: 5px 7px;
	border-radius: 5px;
	font-weight: bold;
	text-transform: uppercase;
}

.product-old-price {
	font-size: 95%;
	font-weight: 400;
	padding-left: 10px;
	color: #8d99ae;
}

.card-badge-price {
	position: absolute;
	top: 6px;
	right: 6px;
	background-color: #ef2809;
	padding: 5px 7px;
	border-radius: 5px;
	font-weight: bold;
	color: #fff;
}

.card-details {
	padding: 10px 0;
	min-height: 80px;
	display: flex;
	justify-content: space-between;
}

.buttons {
	align-items: center;
	margin: 0px;
	padding: 5px 0;
	display: flex;
	justify-content: flex-end;
	gap: 1rem;
}

.heart {
	color: #fafbfd;
	text-transform: uppercase;
	cursor: pointer;
	&:hover {
		color: #ef2809;
	}
	&.active {
		color: #ef2809;
	}
}

@media only screen and (max-width: 576px) {
	.card {
		flex-direction: column;
	}
	.content-main {
		width: 100%;
	}
	.cover-wrapper {
		max-width: 300px;
		margin: auto;
	}
}
