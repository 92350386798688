.Auth-form-container {
	background-color: white;
	max-width: 420px;
	min-height: 500px;
	margin: 2rem auto;
	box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
	border-radius: 8px;
}

.authTabNav:global(.nav) {
	li {
		width: 50%;
		.authTabButton {
			width: 100%;
			padding: 1rem;
			font-weight: 500;
			&:global(.active) {
				border-color: transparent;
				background-color: var(--bs-primary);
				color: #fff;
			}
		}
	}
}

.formContainerInner {
	padding: 30px 12%;
}

.Auth-form-title {
	text-align: center;
	margin-bottom: 1em;
	font-size: 24px;
	color: rgb(34, 34, 34);
	font-weight: 700;
}

.seperator {
	font-weight: 400;
	font-size: 16px;
	text-align: center;
	position: relative;
	z-index: 1;
	&:before {
		content: '';
		border-top: 1px solid var(--bs-border-color);
		position: absolute;
		top: 54%;
		left: 0;
		right: 0;
		z-index: -1;
	}
	span {
		padding: 0 1rem;
		background: #fff;
		font-weight: bold;
	}
}

.googleAuthBtn {
	border: 0;
	background-color: #4285f4;
	color: #fff;
	width: 100%;
	padding: 4px;
	font-weight: 600;
	font-size: 14px;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	gap: 8px;
	span {
		flex-grow: 1;
		margin-left: -20px;
	}
	.autLoglogo {
		width: 30px;
		height: auto;
		background-color: white;
		padding: 5px;
	}
}

.passwordInput {
	z-index: 1 !important;
	padding-right: 28px;
}
