.variationsContainer {
	position: absolute;
	width: 100%;
	height: 100%;
	background-color: #fff;
	opacity: 0.95;
	padding: 16px;
	right: 0;
	top: 0;
	transform: translateX(100%);
	transition: transform 0.5s cubic-bezier(0.4, 0, 0.2, 1);
}
.show {
	transform: translateX(0);
}
