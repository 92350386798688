.Auth-form-container {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 80vh;
	font-family: Arial, Helvetica, sans-serif;
}

.Auth-form {
	width: 420px;
	border: 1px solid transparent;
	border-radius: 5px;
	box-shadow: 0 3px 9px rgba(0, 0, 0, 0.1);
	overflow: hidden;
	padding-top: 30px;
	padding-bottom: 30px;
	background-color: #fafbfd;
	border-radius: 8px;
}

.Auth-form-content {
	padding-left: 12%;
	padding-right: 12%;
}

.Auth-form-title {
	text-align: center;
	margin-bottom: 1em;
	font-size: 24px;
	color: rgb(34, 34, 34);
	font-weight: 700;
}

.form-group {
	font-weight: 400 !important;
	font-size: 18px !important;
}

.form-control {
	line-height: 22px;
	font-size: 16px !important;
}

.style-text {
	font-weight: 400 !important;
	font-size: 16px;
}

.submit {
	font-size: 16px !important;
	font-weight: 600 !important;
	width: 100%;
}
