.section {
	font-weight: 400;
	padding: 20px;
	max-width: 900px;
	margin-left: auto;
	margin-right: auto;
}

.section-title {
	position: relative;
	margin-bottom: 30px;
	margin-top: 15px;
}

.section-title .title {
	display: inline-block;
	text-transform: uppercase;
	margin: 0px;
}

.cart-empty {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 600px;
}

.summary {
	display: flex;
	flex-direction: row;
	background-color: white;
	padding: 1rem;
	margin: 1.5rem 0;
	width: 100%;
}

.cart-total {
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 100%;
	padding: 0 20px;
}

.font-style {
	font-size: 14px;
}

.text-price {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.price {
	display: flex;
	justify-content: flex-end;
	margin-left: auto;
}

.vertical {
	border-left: 1px solid var(--bs-border-color);
}

.total-price {
	font-weight: 700;
}

.total-price-col {
	display: flex;
	flex-direction: column;
	align-self: flex-end;
	justify-content: center;
	min-width: 14rem;
	width: 60%;
	padding: 0 20px;
}

.form-input {
	line-height: 22px;
	font-size: 16px !important;
	max-width: 200px;
}

.clear-cart {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	min-width: 14rem;
	width: 60%;
	padding: 0 20px;
}

@media only screen and (max-width: 581px) {
	.clear-cart {
		padding: 0 5px;
		min-width: 65px;
	}

	.clear-cart > Button {
		font-size: 12px;
		min-width: 65px;
	}

	.cart-total {
		max-width: 150px;
	}
}
